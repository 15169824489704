























import { Component, Prop } from 'vue-property-decorator'

import { ImageProps } from '../../../../dsl/atoms/Image'
import { toImageProps } from '../../../../front/shared/support'

import { UiMarkdown } from '../../../partials/UiMarkdown'
import { AbstractModuleUi } from '../../../abstract/ui'

import { InfoCardModule } from '../../InfoCard.contracts'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component({
  name: 'InfoCardBodyModuleUi',
  components: { UiMarkdown }
})
export class InfoCardBodyUi extends AbstractModuleUi<InfoCardModule> {
  @Prop({ required: true, type: Object })
  public data!: InfoCardModule

  /**
   * Constructs proper ImageProps from left image data.
   */
  public get imageLeft (): ImageProps | boolean {
    if (!this.data.content.imageLeft) {
      return false
    }

    return toImageProps(this.data.content.imageLeft)
  }

  /**
   * Constructs proper ImageProps from right image data.
   */
  public get imageRight (): ImageProps | boolean {
    if (!this.data.content.imageRight) {
      return false
    }

    return toImageProps(this.data.content.imageRight)
  }

  /**
   * Determines InfoCard has headline.
   */
  public get hasHeadline (): boolean {
    return typeof this.data.content.headline !== 'undefined'
  }

  /**
   * Determines InfoCard has content.
   */
  public get hasContent (): boolean {
    return typeof this.data.content.content !== 'undefined'
  }

  /**
   * Determines InfoCard has additional information.
   */
  public get hasAdditionalInfo (): boolean {
    return typeof this.data.content.additionalInfo !== 'undefined'
  }
}
export default InfoCardBodyUi
