












import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'
import { WebinarsListModule } from '../WebinarsList.contracts'

import { InfoCardBody } from '../../InfoCard/ui/_partials'
import { Inject } from '../../../support'
import { ISelenaFormService, SelenaFormServiceType } from '../../../front/shared/services/selenaForms'
import { AnyObject } from '@movecloser/front-core'

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
@Component<WebinarsListModuleUi>({
  name: 'WebinarsListModuleUi',
  components: { InfoCardBody }
})
export class WebinarsListModuleUi extends AbstractModuleUi<WebinarsListModule> {
  @Inject(SelenaFormServiceType)
  protected readonly selenaFormService!: ISelenaFormService

  /**
   * Determines loading state.
   */
  public isLoading: boolean = false

  /**
   *
   */
  public webinars: AnyObject[] = []

  /**
   * Store intercepted error from submitted form.
   */
  public error: Error | null = null

  /**
   * Created lifecycle hook.
   */
  public async created () {
    this.webinars = await this.getWebinars()
  }

  /**
   * Fetch certificates
   */
  private async getWebinars (): Promise<AnyObject[]> {
    this.isLoading = true
    try {
      return await this.selenaFormService.getWebinars()
    } finally {
      this.isLoading = false
    }
  }

  /**
   * MAp webinars to InfoCardBody props.
   * @param webinar
   */
  public mapWebinar (webinar: AnyObject) {
    const date = new Date(webinar.starts_at)

    return {
      content: {
        headline: webinar.name || '',
        content: webinar.description || '',
        separator: 'line',
        additionalInfo: this.$t('front.WebinarsList.date', { date: date.toLocaleDateString(), time: date.toLocaleTimeString().substring(0, 5) }),
        imageLeft: {
          src: webinar.image_url || ''
        }
      }
    }
  }

  /**
   * Add params to url on click.
   * @param webinar
   */
  public handleClick (webinar: AnyObject) {
    const newRoute = { path: this.$route.fullPath, query: { webinar: webinar.id } }
    this.$router.push(newRoute)
  }
}

export default WebinarsListModuleUi
